import React, { useState } from 'react'
import { useCookiesContext } from 'contexts/CookiesContext'
import Cookies from 'lib/cookies'
import { useTranslation } from 'lib/i18n'

import CtaButton from 'components/CtaButton/CtaButton'
import Link from 'components/Link/Link'
import Wrapper from 'components/Wrapper/Wrapper'

import styles from './CookieConsent.module.css'

export default function CookieConsent() {
  const [cookieValue, setCookieValue] = useState(Cookies.get('acceptsCookies'))
  const { setAreCookiesAccepted } = useCookiesContext()
  const { t } = useTranslation()

  const acceptCookies = () => {
    setCookieValue(1)
    Cookies.set('acceptsCookies', 1, { expires: 365 })
    setAreCookiesAccepted(true)
  }

  return (
    <>
      {!cookieValue && (
        <div className={styles.cookieConsent}>
          <Wrapper>
            <div className={styles.cookieConsentContent}>
              <div>
                {t(
                  'CookieConsent.text',
                  'By using memmo and our services you agree to our',
                )}{' '}
                <Link href="/cookie-policy">
                  <a>{t('CookieConsent.linkText', 'Cookie policy')}</a>
                </Link>
              </div>
              <CtaButton
                size="small"
                onClick={acceptCookies}
                data-cy="cookieConsentButton"
              >
                {t('CookieConsent.buttonText', 'I understand')}
              </CtaButton>
            </div>
          </Wrapper>
        </div>
      )}
    </>
  )
}
